import React, { useMemo } from "react";
import { Box, Grid } from "@mui/material";
import { get, map } from "lodash";
import VerticalModifierItem from "./vertical";
import HorizontalModifierItem from "./horizontal";

const ModifierWrapper = ({
  variant,
  limit,
  orderItemComponent,
  onChange,
  isMobile,
  appStyles,
  allergens,
  T,
  shouldShowPlusSign,
  currencySymbol,
  posInnerMenuId,
  withImage = true,
  layout,
}) => {
  const handleChoseOrderItemComponentOption = (
    chosenOrderItemComponentOption,
    amount
  ) => {
    const payload = { ...orderItemComponent };

    if (variant === "radio") {
      payload.chosenOrderItemComponentOptions = [
        chosenOrderItemComponentOption.id,
      ];
    }

    if (variant === "checkbox") {
      payload.chosenOrderItemComponentOptions = orderItemComponent.chosenOrderItemComponentOptions.includes(
        chosenOrderItemComponentOption.id
      )
        ? orderItemComponent.chosenOrderItemComponentOptions.filter(
            (option) => option !== chosenOrderItemComponentOption.id
          )
        : [
            ...orderItemComponent.chosenOrderItemComponentOptions,
            chosenOrderItemComponentOption.id,
          ];
    }

    if (variant === "multiple") {
      payload.chosenOrderItemComponentOptions = [
        ...orderItemComponent.chosenOrderItemComponentOptions.filter(
          (id) => id !== chosenOrderItemComponentOption.id
        ),
        ...Array(amount >= 0 ? amount : 1).fill(chosenOrderItemComponentOption.id),
      ];
    }

    onChange(payload);
  };

  const VerticalLayout = useMemo(
    () => ({ children }) => (
      <Grid
        container
        //TODO: update to 52px for desktop when 3 column layout is implemented
        columnSpacing={isMobile ? 1 : "24px"}
        rowSpacing={isMobile ? "10px" : "24px"}
        alignItems="stretch"
        justifyContent="space-evenly"
      >
        {children}
        {//Add an empty grid item if the number of components is odd
        orderItemComponent.componentOptions.length % 2 !== 0 ? (
          <Grid item sx={{ ...(isMobile && { width: "50%" }) }}>
            <div
              style={{ ...(isMobile ? { maxWidth: 164 } : { width: 244 }) }}
            />
          </Grid>
        ) : null}
      </Grid>
    ),
    [isMobile]
  );

  const HorizontalLayout = useMemo(
    () => ({ children }) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Box>
    ),
    []
  );

  const layoutMap = {
    vertical: {
      wrapper: VerticalLayout,
      item: VerticalModifierItem,
    },
    horizontal: {
      wrapper: HorizontalLayout,
      item: HorizontalModifierItem,
    },
  };

  const { wrapper: LayoutComponent, item: ModifierItem } = layoutMap[
    get(appStyles, "modifierImageConfig.modifierImagesLayout", layout)
  ];

  return (
    <LayoutComponent>
      {map(
        orderItemComponent.componentOptions,
        (componentOption, index, arr) => (
          <ModifierItem
            index={arr.length - 1 !== +index ? index : "last"}
            selected={orderItemComponent.chosenOrderItemComponentOptions.includes(
              componentOption.id
            )}
            disabled={
              limit && limit > 0 &&
              variant !== "radio" &&
              orderItemComponent.chosenOrderItemComponentOptions.length >=
                limit &&
              !orderItemComponent.chosenOrderItemComponentOptions.includes(
                componentOption.id
              )
            }
            componentOption={componentOption}
            onClick={(amount) =>
              handleChoseOrderItemComponentOption(componentOption, amount)
            }
            variant={variant}
            appStyles={appStyles}
            orderItemComponent={orderItemComponent}
            allergens={allergens}
            T={T}
            limit={limit}
            shouldShowPlusSign={shouldShowPlusSign}
            currencySymbol={currencySymbol}
            posInnerMenuId={posInnerMenuId}
            isMobile={isMobile}
            withImage={withImage}
          />
        )
      )}
    </LayoutComponent>
  );
};

export default ModifierWrapper;
